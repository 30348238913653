import { Box, Typography } from "@mui/material";
import React from "react";
import InexButton from "../../../InexButton";
import { ReactComponent as Podcast } from "../../../../resources/images/svg/odyssee/podcast.svg";
import { useIsMobile } from "../../../../hooks/responsive";

const PodcastPreview = ({ webContent, setModalOpen }) => {
	const isMobile = useIsMobile();

	const openContent = () => {
		setModalOpen(true);
	};

	return (
		<Box
			sx={{
				display: "flex",
				width: "100%",
				position: "relative",
				borderRadius: 10,
			}}
		>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					width: "100%",
					"&::after": {
						position: "absolute",
						content: "''",
						backgroundColor: "#00000069",
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
					},
				}}
			>
				<img
					src={webContent?.image}
					style={{
						height: "auto",
						maxHeight: 350,
						width: "100%",
						alignSelf: "center",
						borderRadius: 10,
						objectFit: "contain",
						position: "relative",
					}}
				/>
			</Box>
			<Box
				sx={{
					position: "absolute",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					top: 0,
					bottom: 0,
					right: 0,
					left: 0,
					borderRadius: 10,
				}}
			>
				<InexButton
					startIcon={<Podcast />}
					onClick={openContent}
					variant={"text"}
					textWithGradient={false}
					text={"Écouter le podcast"}
					textSx={{
						color: "white",
					}}
					sx={{
						backgroundColor: "#EF7D8F",
						width: 230,
						transition: "opacity 0.3s ease box-shadow 0.3s ease, transform 0.3s ease",
						"&:hover": {
							boxShadow: "0 0 20px 10px rgba(230, 138, 150, 0.5)",
							backgroundColor: "#EF7D8F",
							border: "none",
						},
					}}
				/>
				<Typography
					sx={{ position: "absolute", bottom: 10, left: "50%", transform: "translateX(-50%)" }}
					color={"#FFFFFF"}
					fontFamily={"Branding Bold"}
					fontSize={20}
					dangerouslySetInnerHTML={{ __html: webContent.titre }}
					textAlign={isMobile ? "center" : "left"}
				/>
			</Box>
		</Box>
	);
};

export default PodcastPreview;
