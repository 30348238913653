import React, { useMemo } from "react";
import { Box, Grid, Skeleton, styled, Typography } from "@mui/material";
import { useGetCurrentSubscriptionsQuery } from "../store/api/subscriptions";
import { useAuthToken } from "../hooks/auth";
import AbonnementCard from "../components/abonnement/home/AbonnementCard";
import { useGetUserAddressQuery } from "../store/api/address";
import { useIsTablet } from "../hooks/responsive";
import OldAbonnementCard from "../components/abonnement/home/OldAbonnementCard";
import withAuth from "../HOC/withAuth";
import moment from "moment/moment";
import useTabTitle from "../hooks/useTabTitle";
import SmartsuppChat from "../components/SmartsuppChat";

export const ContentContainer = styled(Grid)(({ theme, selected }) => {
	const isTablet = useIsTablet();

	return {
		width: "100%",
		padding: `${theme.spacing(5)} ${theme.spacing(isTablet ? 2 : 6)}`,
		background: "#fffc",
		// backgroundColor: "rgb(253,247,224, 0.7)",
		// background: "linear-gradient(90deg, rgba(253,247,224,1) 0%, rgba(253,244,224,1) 22%, rgba(253,244,236,1) 34%, rgba(253,240,233,1) 36%, rgba(254,242,236,1) 51%, rgba(254,254,253,1) 59%, rgba(254,248,238,1) 74%, rgba(253,244,236,1) 100%)",
	};
});

export const SUBSCRIPTIONS_TYPES = {
	Mag: 3,
	Digital: 1,
	Tv: 2,
};

export const Title = styled(Typography)((theme) => {
	return {
		textAlign: "left",
		color: "#F07892",
		fontFamily: "Branding Bold",
		fontWeight: 600,
		fontSize: 20,
		textTransform: "uppercase",
		letterSpacing: 3,
	};
});

export const SubTitle = styled(Typography)(({ theme }) => {
	return {
		textAlign: "left",
		color: theme.palette.secondary.main,
		fontFamily: "Branding SemiBold",
		fontWeight: 600,
		fontSize: 20,
	};
});

const Abonnement = () => {
	useTabTitle({ customTitle: "Mes abonnements" });
	const authToken = useAuthToken();
	const { data: { newabos, oldabos } = {}, isLoading } = useGetCurrentSubscriptionsQuery({}, { skip: !authToken });
	const { data: userAddress } = useGetUserAddressQuery({ idadresse: newabos?.idadresse }, { skip: !newabos });

	const _showLoader = useMemo(() => {
		return isLoading || !authToken;
	}, [isLoading, authToken]);

	const _nbActifSubscriptions = useMemo(() => {
		let count = 0;
		if (!!newabos?.digital && moment().isBefore(newabos?.digital?.daterenouv * 1000)) count++;
		if (!!newabos?.tv && moment().isBefore(newabos?.tv?.daterenouv * 1000)) count++;
		if (!!newabos?.mag && moment().isBefore(newabos?.mag?.daterenouv * 1000)) count++;
		return count;
	}, [newabos]);

	const _nbActifOldSubscriptions = useMemo(() => {
		return oldabos && Object.keys(oldabos).length;
	}, [oldabos]);

	return (
		<ContentContainer className={"content-background"}>
			<Box sx={{ width: "100%", paddingBottom: (theme) => theme.spacing(3) }}>
				{!!oldabos && _nbActifOldSubscriptions > 0 && (
					<Box>
						<Box>
							{_showLoader ? (
								<Skeleton
									sx={{
										backgroundColor: "white",
										boxShadow: (theme) => theme.shadows[25],
										borderRadius: "8px",
									}}
									variant="rectangular"
									width={"220px"}
									height={"50px"}
									animation={"pulse"}
								/>
							) : (
								<Title>Mes abonnements (Anciennes formules)</Title>
							)}
						</Box>
						<Box>
							{_showLoader ? (
								<Skeleton
									sx={{
										backgroundColor: "white",
										boxShadow: (theme) => theme.shadows[25],
										borderRadius: "8px",
										marginTop: (theme) => theme.spacing(2),
									}}
									variant="rectangular"
									width={"300"}
									height={"50px"}
									animation={"pulse"}
								/>
							) : (
								<SubTitle sx={{ marginTop: (theme) => theme.spacing(2) }}>{`Vous avez ${
									_nbActifOldSubscriptions || ""
								} abonnement${_nbActifOldSubscriptions > 1 ? "s" : ""} en cours`}</SubTitle>
							)}
						</Box>
						<Grid
							container
							columnSpacing={2}
							rowSpacing={2}
							alignItems={"stretch"}
							sx={{ marginTop: (theme) => theme.spacing(2), marginBottom: (theme) => theme.spacing(4) }}
						>
							{Object.entries(oldabos)?.map(([titre, item]) => (
								<OldAbonnementCard key={item.titre} subscription={item} />
							))}
						</Grid>
					</Box>
				)}
				<Box>
					{_showLoader ? (
						<Skeleton
							sx={{
								backgroundColor: "white",
								boxShadow: (theme) => theme.shadows[25],
								borderRadius: "8px",
							}}
							variant="rectangular"
							width={"220px"}
							height={"50px"}
							animation={"pulse"}
						/>
					) : (
						<Title>Mes abonnements </Title>
					)}
				</Box>
				<Box>
					{_showLoader ? (
						<Skeleton
							sx={{
								backgroundColor: "white",
								boxShadow: (theme) => theme.shadows[25],
								borderRadius: "8px",
								marginTop: (theme) => theme.spacing(2),
							}}
							variant="rectangular"
							width={"300"}
							height={"50px"}
							animation={"pulse"}
						/>
					) : (
						<SubTitle sx={{ marginTop: (theme) => theme.spacing(3) }}>
							{_nbActifSubscriptions > 0
								? `Vous avez ${_nbActifSubscriptions || ""} abonnement${
										_nbActifSubscriptions > 1 ? "s" : ""
								  } en cours`
								: "Vous n'avez aucun abonnement en cours "}
						</SubTitle>
					)}
				</Box>
				<Grid
					container
					columnSpacing={2}
					rowSpacing={2}
					alignItems={"stretch"}
					sx={{ marginTop: (theme) => theme.spacing(2) }}
				>
					{_showLoader ? (
						<>
							{Array.from(Array(3)).map((_, index) => (
								<Grid item xs={6} key={index}>
									<Skeleton
										sx={{
											backgroundColor: "white",
											boxShadow: (theme) => theme.shadows[25],
											borderRadius: "8px",
										}}
										variant="rectangular"
										width={"100%"}
										height={"350px"}
										animation={"pulse"}
									/>
								</Grid>
							))}
						</>
					) : (
						<>
							{!(!!newabos?.tv && (moment().isBefore(newabos?.tv?.daterenouv * 1000) || newabos?.tv?.typerenouv > 0)) && (
								<AbonnementCard
									subscription={newabos?.digital}
									subTitle={"Actualités, podcasts et accès illimité à tous nos magazines numériques."}
									title={"Inexploré digital"}
									subscriptionType={SUBSCRIPTIONS_TYPES.Digital}
									address={userAddress?.adresse}
									isSub={(!!newabos?.digital && moment().isBefore(newabos?.digital?.daterenouv * 1000))}
								/>
							)}
							<AbonnementCard
								subscription={newabos?.tv}
								subTitle={"Accès illimité à notre catalogue de vidéos, magazines numériques, articles web et podcasts."}
								title={"Inexploré TV + digital"}
								subscriptionType={SUBSCRIPTIONS_TYPES.Tv}
								address={userAddress?.adresse}
								isSub={(!!newabos?.tv && moment().isBefore(newabos?.tv?.daterenouv * 1000))}
							/>
							<AbonnementCard
								subscription={newabos?.mag}
								subTitle={"Notre revue trimestrielle, livrée chez vous au format papier."}
								title={"Inexploré magazine"}
								subscriptionType={SUBSCRIPTIONS_TYPES.Mag}
								address={userAddress?.adresse}
								isSub={(!!newabos?.mag && moment().isBefore(newabos?.mag?.daterenouv * 1000))}
							/>
						</>
					)}
				</Grid>
			</Box>
			{/* Intégration de Smartsupp */}
			<SmartsuppChat />
		</ContentContainer>
	);
};

export default withAuth(Abonnement);
