import React from "react";
import { Box, styled, Typography } from "@mui/material";

const BackgroundImage = styled(Box)(({ theme }) => ({
	backgroundPosition: "50%",
	backgroundRepeat: "no-repeat",
	backgroundSize: "cover",
	display: "flex",
	filter: "blur(10px)",
	height: "100%",
	position: "absolute",
	width: "100%",
	zIndex: 1,
}));

const Video = ({ webContent }) => {
	return (
		<Box
			sx={{
				backgroundColor: "#18122D",
				display: "flex",
				alignItems: "center",
				flexDirection: "column",
				padding: "40px 30px",
			}}
		>
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					width: "100%",
					"& > iframe": { width: "100%" },
				}}
				dangerouslySetInnerHTML={{ __html: webContent?.iframe }}
			/>
			<Typography mt={5} color={"#ffffff"} fontFamily={"Branding Bold"} fontSize={30} alignSelf={"flex-start"}>
				{webContent.titre}
			</Typography>
		</Box>
	);
};

export default Video;
