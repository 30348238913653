import React from "react";
import { Box, styled, Typography } from "@mui/material";

const BackgroundImage = styled(Box)(({ theme }) => ({
	backgroundPosition: "50%",
	backgroundRepeat: "no-repeat",
	backgroundSize: "cover",
	display: "flex",
	filter: "blur(10px)",
	height: "100%",
	position: "absolute",
	width: "100%",
	zIndex: 1,
}));
const BackgroundOpacity = styled(Box)(({ theme }) => ({
	backgroundPosition: "50%",
	backgroundRepeat: "no-repeat",
	backgroundSize: "cover",
	display: "flex",
	filter: "blur(10px)",
	height: "100%",
	position: "absolute",
	width: "100%",
	zIndex: 1,
	background: "#00000088",
}));

const Film = ({ webContent }) => {
	return (
		<Box sx={{ backgroundColor: "#182B43" }}>
			<BackgroundImage style={{ backgroundImage: `url(${webContent.image})` }}></BackgroundImage>
			<BackgroundOpacity></BackgroundOpacity>
			<Box sx={{ display: "flex", gap: 3, alignItems: "stretch", zIndex: 2, position: "relative", padding: 5 }}>
				<Box
					sx={{
						alignItems: "center",
						background: "#262835",
						borderRadius: 10,
						display: "flex",
						justifyContent: "center",
						maxWidth: 350,
						padding: 3,
						width: "100%",
					}}
				>
					<img src={webContent?.image} style={{ height: 250 }} />
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-evenly",
						flexDirection: "column",
						gap: 3,
					}}
				>
					<Typography color={"#ffffff"} fontFamily={"Branding SemiBold"}>
						{webContent?.titre}
					</Typography>
					<Typography
						color={"#ffffff"}
						fontFamily={"Branding SemiBold"}
						dangerouslySetInnerHTML={{ __html: webContent.description }}
					/>
				</Box>
			</Box>
		</Box>
	);
};

export default Film;
