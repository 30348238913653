import React from "react";
import { Box, styled, Typography } from "@mui/material";
import { useIsMobile } from "../../../../hooks/responsive";

const Tag = styled(Box)(({ theme }) => ({
	border: "1px solid #A5D0C9",
	borderRadius: "40px",
	width: "fit-content",
	padding: "8px 20px",
	marginTop: 40,
}));

const BookContainer = styled(Box)(() => {
	return {
		display: "flex",
		flexDirection: "column",
		gap: 10,
		width: 210,
		backgroundColor: "#FFFFFE1C",
		padding: "10px",
		borderRadius: "10px",
	};
});

const Collection = ({ webContent, category = "books" }) => {
	const isMobile = useIsMobile();

	const collection = webContent.livres ?? webContent?.films;
	return (
		<Box sx={{ backgroundColor: "#18122D", padding: "40px 40px" }}>
			<Typography
				textAlign={isMobile ? "center" : "left"}
				color={"#ffffff"}
				fontFamily={"Branding Bold"}
				fontSize={30}
			>
				Votre collection de {category === "books" ? "livres" : "films"}
			</Typography>
			<Box
				sx={{
					display: "flex",
					gap: 4,
					alignItems: "stretch",
					zIndex: 2,
					position: "relative",
					flexWrap: "wrap",
					marginTop: 4,
					justifyContent: isMobile ? "center" : "flex-start",
				}}
			>
				{collection?.map((livre) => (
					<BookContainer>
						<img
							src={livre?.image}
							style={{ height: 300, width: 190, objectFit: "cover", borderRadius: "10px" }}
						/>
						<Typography color={"#ffffff"} fontFamily={"Branding SemiBold"}>
							{livre?.titre}
						</Typography>
					</BookContainer>
				))}
			</Box>
			<Typography color={"#ffffff"} fontFamily={"Branding Bold"} fontSize={30} mb={3} mt={4}>
				Réflexions connexes
			</Typography>
			{webContent?.connexes?.map((connex) => (
				<Box>
					<Tag>
						<Typography
							color={"#A5D0C9"}
							fontFamily={"Branding SemiBold"}
							fontSize={16}
							textTransform={"capitalize"}
						>
							{connex?.name}
						</Typography>
					</Tag>
					<Box
						sx={{
							display: "flex",
							gap: 4,
							alignItems: "stretch",
							zIndex: 2,
							position: "relative",
							marginTop: 4,
							flexWrap: "wrap",
							justifyContent: isMobile ? "center" : "flex-start",
						}}
					>
						{connex?.items?.map((livre) => (
							<BookContainer>
								<img
									src={livre?.image}
									style={{ height: 300, width: 190, objectFit: "cover", borderRadius: "10px" }}
								/>
								<Typography color={"#ffffff"} fontFamily={"Branding SemiBold"}>
									{livre?.titre}
								</Typography>
							</BookContainer>
						))}
					</Box>
				</Box>
			))}
		</Box>
	);
};

export default Collection;
