import React from "react";
import { Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Box, styled, Typography } from "@mui/material";
import useOdyssee from "../../../context/odyssee/useOdyssee";
import InexButton from "../../InexButton";
import { ReactComponent as RightArrow } from "../../../resources/images/svg/odyssee/right-arrow.svg";
import { ReactComponent as Done } from "../../../resources/images/svg/odyssee/done.svg";
import { GradientTypo } from "../../header/Header";
import "swiper/css/navigation";

import "swiper/css/pagination";
import useOdysseeNavigation from "../../../context/odysseeNavigation/useOdyssee";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { computeStepsBeforeModuleIndex } from "../../../context/odyssee";

const CardContainer = styled(Box, {
	shouldForwardProp: (prop) => prop !== "isActive" && prop !== "isDone" && prop !== "isModuleActive",
})(({ isActive, isDone, isModuleActive }) => {
	const bottomOffset = isActive ? 80 : 50;
	let props = isActive
		? {
				border: "2px solid",
				borderImage: "linear-gradient(90deg, #EE7992 20%, #F1AE65 50.51%, #F4C54D 100%) 1",
		  }
		: {};
	return {
		backgroundColor: "#27213B",
		padding: 15,
		display: "flex",
		flexDirection: "column",
		gap: 10,
		opacity: isActive ? 1 : 0.5,
		height: isModuleActive ? `calc(100% - ${bottomOffset}px)` : "100%",
		"&:hover": {
			backgroundColor: isActive || isDone ? "rgba(39,33,59,0.70)" : "#27213B",
			opacity: isActive || isDone ? 1 : 0.5,
		},
		transition: "all 0.3s ease",
		cursor: isActive || isDone ? "pointer" : "grab",
		...props,
	};
});

const SummaryCard = ({ step, index = 1, length }) => {
	const { navigateToStep } = useOdysseeNavigation();
	const {
		progressModuleId,
		currentModuleIndex,
		databaseModuleIndex,
		databaseStepIndex,
		modules,
		progressModuleIndex,
	} = useOdyssee();

	const isActive = React.useMemo(() => {
		return index === databaseStepIndex && step?.idetape === modules[databaseModuleIndex]?.id;
	}, [databaseStepIndex, index, progressModuleId]);

	const isModuleActive = React.useMemo(() => {
		return currentModuleIndex === databaseModuleIndex;
	}, [currentModuleIndex, databaseModuleIndex]);

	const isDone = React.useMemo(() => {
		if (currentModuleIndex < databaseModuleIndex) return true;
		return currentModuleIndex === databaseModuleIndex && index < databaseStepIndex;
	}, [currentModuleIndex, databaseModuleIndex, databaseStepIndex]);

	const devUrl = React.useMemo(() => {
		if (window.location.hostname === "localhost") {
			// return "dev/";
			return "";
		}
		return "";
	}, []);

	const progress = React.useMemo(() => {
		const stepValue = 360 / length;
		return stepValue * index + stepValue;
	}, [length, index]);

	const initialOffset = React.useMemo(() => {
		return computeStepsBeforeModuleIndex(progressModuleIndex, modules);
	}, [progressModuleIndex, modules]);

	return (
		<Box sx={{ height: "100%" }}>
			<CardContainer
				onClick={() => {
					if (isActive || isDone) {
						navigateToStep(step?.idetape, step.id);
					}
				}}
				isActive={isActive}
				isDone={isDone}
				isModuleActive={isModuleActive}
			>
				{!!isDone ? (
					<Typography color={"#ffffff"} fontFamily={"Branding Bold"} fontSize={20}>{`ETAPE ${
						index + 1 + initialOffset
					}`}</Typography>
				) : (
					<GradientTypo fontSize={20}>{`ETAPE ${index + 1 + initialOffset}`}</GradientTypo>
				)}
				{/*<Box>*/}
				{/*	<Box*/}
				{/*		sx={{*/}
				{/*			width: "100%",*/}
				{/*			height: 200,*/}
				{/*			backgroundImage: `url('https://medias.inrees.com/img/odyssees/${devUrl}modules/1_${currentModule.id}.jpg')` ,*/}
				{/*			backgroundSize: "cover",*/}
				{/*			backgroundPosition: "center",*/}
				{/*			maskImage: `conic-gradient(#000 0deg ${progress}deg, transparent ${progress}deg 360deg);`,*/}
				{/*			"-webkit-mask-image": `conic-gradient(#000 0deg ${progress}deg, transparent ${progress}deg 360deg);`,*/}
				{/*		}}*/}
				{/*	></Box>*/}
				{/*</Box>*/}
				<Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 5 }}>
					<Typography color={"#ffffff"} fontFamily={"Branding SemiBold"} fontSize={18}>
						{step.titre}
					</Typography>
					{!!isDone && <Done style={{ minWidth: 40 }} />}
				</Box>
			</CardContainer>
			{!!isActive && (
				<InexButton
					onClick={() => {
						navigateToStep(step?.idetape, step.id);
					}}
					variant={"text"}
					textWithGradient={false}
					text={"Poursuivre l'odyssée"}
					endIcon={<RightArrow />}
					textSx={{
						color: "#182B43",
					}}
					sx={{
						background: "linear-gradient(90deg, #EE7992 0%, #F1AE65 50.51%, #F4C54D 100%)",
						width: "100%",
						marginTop: "30px",
					}}
				/>
			)}
		</Box>
	);
};

const SummarySteps = ({ steps }) => {
	const { width } = useWindowDimensions();
	const { databaseStepIndex } = useOdyssee();

	if (isNaN(databaseStepIndex)) return null;

	return (
		<Swiper
			slidesPerView={width / 340}
			spaceBetween={30}
			pagination={{
				clickable: true,
			}}
			navigation={true}
			modules={[Pagination, Navigation]}
			className="summary-odyssee-step-swiper"
			slidesOffsetBefore={30}
			slidesOffsetAfter={30}
			style={{ cursor: "grab", paddingBottom: 60 }}
			initialSlide={databaseStepIndex}
		>
			{steps?.map((step, index) => (
				<SwiperSlide key={`step-${step.id}`}>
					<SummaryCard index={index} step={step} length={steps.length} />
				</SwiperSlide>
			))}
		</Swiper>
	);
};
export default SummarySteps;
