import React from "react";
import { Box, styled, Typography } from "@mui/material";
import { useIsMobile } from "../../../hooks/responsive";

const Container = styled(Box)(() => {
	return {
		display: "flex",
		flexDirection: "row",
		backgroundColor: "#FFFFFE1A",
		borderRadius: 40,
		position: "relative",
		cursor: "pointer",
	};
});

const Item = styled(Box, { shouldForwardProp: (prop) => prop !== "isMobile" })(({ isMobile }) => {
	return {
		width: isMobile ? 150 : 200,
		height: 57,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: 40,
	};
});

const Indicator = styled(Box, { shouldForwardProp: (prop) => prop !== "isMobile" })(({ isMobile }) => {
	return {
		width: isMobile ? 150 : 200,
		height: 57,
		position: "absolute",
		borderRadius: 40,
		background: "linear-gradient(90deg, #EE7992 0%, #F1AE65 50.51%, #F4C54D 100%)",
		transition: "transform 0.3s ease",
	};
});

const MenuOdyssee = ({ elements, onClick }) => {
	const [selectedIndex, setSelectedIndex] = React.useState(0);
	const isMobile = useIsMobile();

	const ITEM_WIDTH = isMobile ? 150 : 200;

	const onItemClicked = (index) => {
		setSelectedIndex(index);
	};

	return (
		<Container>
			<Indicator isMobile={isMobile} sx={{ transform: `translateX(${selectedIndex * ITEM_WIDTH}px)` }} />
			{elements.map((item, index) => (
				<Item
					isMobile={isMobile}
					key={item.id}
					onClick={() => {
						if (!!item?.disabled) return;
						onClick?.({ ...item, index });
						onItemClicked(index);
					}}
				>
					<Typography
						sx={{
							position: "relative",
							zIndex: 3,
							transition: "color 0.3s ease",
							userSelect: "none",
							opacity: !!item?.disabled ? 0.5 : 1,
						}}
						fontFamily={"Branding SemiBold"}
						fontSize={18}
						color={index === selectedIndex ? "#182B43" : "#FFFFFF"}
					>
						{item?.name}
					</Typography>
				</Item>
			))}
		</Container>
	);
};

export default MenuOdyssee;
