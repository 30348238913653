import React, { useEffect } from "react";

const SmartsuppChat = () => {
  useEffect(() => {
    // Ajouter le script Smartsupp au chargement du composant
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src = "https://www.smartsuppchat.com/loader.js?";
    script.charset = "utf-8";

    const initializeSmartsupp = () => {
      window._smartsupp = window._smartsupp || {};
      window._smartsupp.key = "c27062ada923a45a1512f0ec1e7e135e1bdf7701";
      window.smartsupp = window.smartsupp || function () {
        window.smartsupp._.push(arguments);
      };
      window.smartsupp._ = [];
    };

    script.onload = initializeSmartsupp;

    // Ajouter le script dans le <head>
    document.head.appendChild(script);

    // Nettoyage lors du démontage
    return () => {
      document.head.removeChild(script);
      delete window._smartsupp;
      delete window.smartsupp;
    };
  }, []);

  return null; // Pas besoin de rendre quoi que ce soit
};

export default SmartsuppChat;
