import React from "react";
import { GradientTypo } from "../../header/Header";
import { Box } from "@mui/material";
import useOdyssee from "../../../context/odyssee/useOdyssee";
import MenuOdyssee from "./MenuOdyssee";
import Home from "./Home";
import Summary from "./summary";
import { motion } from "motion/react";
import { useIsMobile } from "../../../hooks/responsive";

const MENU_ELEMENTS = [
	{ name: "Mon odyssée", id: 0, disabled: false },
	{ name: "Mes étapes", id: 1, disabled: false },
	{ name: "Communauté", id: 2, disabled: true },
	{ name: "Aller plus loin", id: 3, disabled: true },
];

const OdysseeHome = () => {
	const [currentPage, setCurrentPage] = React.useState(0);
	const isMobile = useIsMobile();

	const { odyssee } = useOdyssee();

	const menuElement = React.useMemo(() => {
		if (isMobile) {
			return [
				{ name: "Mon odyssée", id: 0, disabled: false },
				{ name: "Mes étapes", id: 1, disabled: false },
			];
		}
		return [
			{ name: "Mon odyssée", id: 0, disabled: false },
			{ name: "Mes étapes", id: 1, disabled: false },
			{ name: "Communauté", id: 2, disabled: true },
			{ name: "Aller plus loin", id: 3, disabled: true },
		];
	}, [isMobile]);

	return (
		<Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
			<GradientTypo fontSize={isMobile ? "30px" : "40px"} sx={{ marginBottom: 5, marginLeft: isMobile ? 5 : 0 }}>
				{odyssee?.titre}
			</GradientTypo>
			<Box sx={{ position: "relative", zIndex: 2 }}>
				<MenuOdyssee elements={menuElement} onClick={({ index }) => setCurrentPage(index)} />
			</Box>
			{currentPage === 0 && (
				<motion.div
					style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
					exit={{ opacity: 0 }}
					transition={{ type: "spring", duration: 1 }}
					initial={{ opacity: 0, scale: 0.95 }}
					animate={{ opacity: 1, scale: 1 }}
				>
					<Home />
				</motion.div>
			)}
			{currentPage === 1 && (
				<motion.div
					exit={{ opacity: 0 }}
					transition={{ type: "spring", duration: 1 }}
					initial={{ opacity: 0, scale: 0.95 }}
					animate={{ opacity: 1, scale: 1 }}
				>
					<Summary />
				</motion.div>
			)}
		</Box>
	);
};

export default OdysseeHome;
