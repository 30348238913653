import { Box, Typography } from "@mui/material";
import React from "react";
import InexButton from "../../../InexButton";
import { ReactComponent as Video } from "../../../../resources/images/svg/odyssee/video.svg";
import { useIsMobile } from "../../../../hooks/responsive";

const VideoPreview = ({ webContent, setModalOpen }) => {
	const isMobile = useIsMobile();

	const openContent = () => {
		setModalOpen(true);
	};

	return (
		<Box
			sx={{
				display: "flex",
				width: "75%",
				position: "relative",
				borderRadius: 10,
				"&::after": {
					position: "absolute",
					content: "''",
					backgroundColor: "#00000069",
					left: 0,
					right: 0,
					bottom: 0,
					height: 120,
					borderRadius: "10px",
					background: "linear-gradient(178.18deg, rgba(0, 0, 0, 0) 1.33%, rgba(0, 0, 0, 0.7) 98.24%)",
				},
			}}
		>
			<img
				src={webContent?.imageHome || webContent?.image}
				style={{
					height: "auto",
					width: "100%",
					alignSelf: "center",
					borderRadius: 10,
					maxHeight: 500,
					objectFit: "cover",
					minHeight: 250,
				}}
			/>
			<Box
				sx={{
					position: "absolute",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					top: 0,
					bottom: 0,
					right: 0,
					left: 0,
					borderRadius: 10,
					zIndex: 2,
				}}
			>
				<InexButton
					startIcon={<Video />}
					onClick={openContent}
					variant={"text"}
					textWithGradient={false}
					text={"Accéder à la vidéo"}
					textSx={{
						color: "white",
					}}
					sx={{
						backgroundColor: "#EF7D8F",
						width: 230,
						transition: "opacity 0.3s ease box-shadow 0.3s ease, transform 0.3s ease",
						"&:hover": {
							boxShadow: "0 0 20px 10px rgba(230, 138, 150, 0.5)",
							backgroundColor: "#EF7D8F",
							border: "none",
						},
					}}
				/>
				<Typography
					sx={{ position: "absolute", bottom: 10, left: 20 }}
					color={"#FFFFFF"}
					fontFamily={"Branding Bold"}
					fontSize={20}
					dangerouslySetInnerHTML={{ __html: webContent.titre }}
					textAlign={isMobile ? "center" : "left"}
				/>
			</Box>
		</Box>
	);
};

export default VideoPreview;
