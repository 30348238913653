import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, styled, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import useOdyssee from "../../../../context/odyssee/useOdyssee";
import { ReactComponent as Clock } from "../../../../resources/images/svg/odyssee/clock.svg";
import HomeSummaryStep from "./HomeSummaryStep";
import useOdysseeNavigation from "../../../../context/odysseeNavigation/useOdyssee";
import { GradientTypo } from "../../../header/Header";
import { useIsMobile } from "../../../../hooks/responsive";

const InexAccordion = styled(Accordion)(() => {
	return {
		backgroundColor: "#FFFFFF0F",
		borderRadius: "20px",
		padding: "20px",
		borderTopLeftRadius: "20px !important",
		borderTopRightRadius: "20px !important",
		borderBottomRightRadius: "20px !important",
		borderBottomLeftRadius: "20px !important",
		maxWidth: "90vw",
		"..MuiAccordion-root": {},
	};
});

const SummaryAccordionItem = ({ module, index, devUrl }) => {
	const [isExpanded, setExpanded] = React.useState(false);
	const { progressModuleId, databaseModuleIndex, databaseStepIndex, modules } = useOdyssee();
	const { navigateToStep } = useOdysseeNavigation();
	const isMobile = useIsMobile();

	const isNext = React.useMemo(() => {
		return databaseStepIndex > index;
	}, [index, databaseModuleIndex]);

	const isActive = React.useMemo(() => {
		return index === databaseModuleIndex;
	}, [index, databaseModuleIndex]);

	const { timeRecommended, timeOptional } = React.useMemo(() => {
		return module?.lecon?.reduce(
			(acc, value) => {
				if (!value.id) return acc;
				if (value.type === "Recommandé") {
					acc.timeRecommended += value?.minutes || 0;
				} else {
					acc.timeOptional += value?.minutes || 0;
				}
				return acc;
			},
			{ timeRecommended: 0, timeOptional: 0 }
		);
	}, [module]);

	const { stepsDone, totalSteps } = React.useMemo(() => {
		const totalSteps = module?.lecon?.length;
		if (index < databaseModuleIndex) return { totalSteps, stepsDone: totalSteps };
		if (index > databaseModuleIndex) return { totalSteps, stepsDone: 0 };
		return { totalSteps, stepsDone: databaseStepIndex };
	}, [databaseModuleIndex, databaseStepIndex, module]);

	return (
		<InexAccordion onChange={(event, expanded) => setExpanded(expanded)}>
			<AccordionSummary
				aria-controls="panel1-content"
				id="panel1-header"
				sx={{
					opacity: isNext ? 1 : 0.5,
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexWrap: isMobile ? "wrap" : "none",
						justifyContent: "center",
						width: "100%",
					}}
				>
					<img
						src={`https://medias.inrees.com/img/odyssees/${devUrl}modules/1_${module.id}.jpg`}
						style={{
							// height: 100,
							width: 234,
							borderRadius: 10,
							marginRight: 20,
						}}
					/>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "space-between",
							width: "100%",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "space-between",
								gap: isMobile ? 0 : 10,
								mb: isMobile ? 2 : 0,
								mt: isMobile ? 2 : 0,
							}}
						>
							{isActive ? (
								<GradientTypo
									textAlign={isMobile ? "center" : "left"}
									fontSize={isMobile ? 20 : 24}
									fontFamily={"Branding Bold"}
								>
									{module?.titre}
								</GradientTypo>
							) : (
								<Typography
									textAlign={isMobile ? "center" : "left"}
									color={"#ffffff"}
									fontFamily={"Branding Bold"}
									fontSize={isMobile ? 20 : 24}
								>
									{module.titre}
								</Typography>
							)}
							{!isMobile && (
								<ExpandMoreIcon
									sx={{
										color: "white",
										transform: `rotate(${isExpanded ? 180 : 0}deg)`,
										transition: "transform 0.2s ease",
									}}
								/>
							)}
						</Box>
						<Box
							sx={{
								display: "flex",
								flexDirection: isMobile ? "column" : "row",
								justifyContent: "space-between",
								alignItems: "center",
								gap: isMobile ? 2 : 0,
							}}
						>
							<Box
								sx={{
									backgroundColor: "#FFFFFF1A",
									borderRadius: "50px",
									display: "flex",
									padding: "10px 20px",
									width: "fit-content",
								}}
							>
								<Typography color={"#FFFFFF"} fontFamily={"Branding Medium"}>
									Module {String.fromCharCode(65 + index)}
								</Typography>
								<Clock stroke={"white"} style={{ marginLeft: 10, marginRight: 7 }} />
								<Typography color={"#FFFFFF"} fontFamily={"Branding Medium"}>
									entre {timeRecommended} min et {timeOptional}
								</Typography>
							</Box>
							<Typography color={"#ffffff"} fontFamily={"Branding Bold"} fontSize={20}>
								{stepsDone ?? 0}/{totalSteps ?? 0} étapes réalisées
							</Typography>
						</Box>
					</Box>
				</Box>
			</AccordionSummary>
			<AccordionDetails>
				<Box>
					<Grid container spacing={3}>
						{module?.lecon?.map((step, stepIndex) => (
							<Grid item key={stepIndex} xs={12} md={6}>
								<HomeSummaryStep
									step={step}
									moduleIndex={index}
									stepIndex={stepIndex}
									modules={modules}
									progressModuleIndex={progressModuleId}
									databaseModuleIndex={databaseModuleIndex}
									databaseStepIndex={databaseStepIndex}
									navigateToStep={navigateToStep}
								/>
							</Grid>
						))}
					</Grid>
				</Box>
			</AccordionDetails>
		</InexAccordion>
	);
};

const HomeSummary = () => {
	const { modules } = useOdyssee();

	const devUrl = React.useMemo(() => {
		if (window.location.hostname === "localhost") {
			// return "dev/";
			return "";
		}
		return "";
	}, []);

	return (
		<React.Fragment>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "stretch",
					marginTop: 6,
					gap: 3,
					marginBottom: 6,
				}}
			>
				{modules?.map((module, index) => (
					<SummaryAccordionItem index={index} key={index} module={module} devUrl={devUrl} />
				))}
			</Box>
		</React.Fragment>
	);
};

export default HomeSummary;
