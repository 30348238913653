import { styled, Typography } from "@mui/material";

const FormatedText = styled(Typography)(() => {
	return {
		color: "#ffffff",
		fontFamily: "Branding Medium",
		"* > *": { color: "#ffffff !important", fontFamily: "Branding Medium" },
		strong: { fontFamily: "Branding SemiBold" },
	};
});

const Text = ({ block }) => {
	return <FormatedText dangerouslySetInnerHTML={{ __html: block.contenu }} />;
};

export default Text;
