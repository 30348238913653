import React from "react";
import { Box, Fade, styled, Typography } from "@mui/material";
import Image from "../OdysseeBlocs/Image";
import Video from "../OdysseeBlocs/Video";
import Text from "../OdysseeBlocs/Text";
import WebContent from "../OdysseeBlocs/WebContent/index";
import Podcast from "../OdysseeBlocs/Podcast";
import Pdf from "../OdysseeBlocs/Pdf";
import { ReactComponent as Clock } from "../../../resources/images/svg/odyssee/clock.svg";
import StepPodcastPlayer from "./StepPodcastPlayer";

const Container = styled(Box)(() => {
	return {
		backgroundColor: "#FFFFFF0F",
		padding: 20,
		borderRadius: "20px",
	};
});

const RenderContent = ({ bloc }) => {
	const renderBlock = () => {
		switch (bloc?.categorie) {
			case "image":
				return <Image block={bloc} />;
			case "video":
				return <Video block={bloc} />;
			case "text":
				return <Text block={bloc} />;
			case "web":
				return <WebContent block={bloc} />;
			case "podcast":
				return <Podcast block={bloc} />;
			case "pdf":
				return <Pdf block={bloc} />;
			default:
				return <Image block={bloc} />;
		}
	};

	return renderBlock();
};

const StepContentContainer = ({ currentStep, ressources }) => {
	console.log(currentStep);
	return (
		<Container>
			<Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: 3 }}>
				<Fade in={!!currentStep}>
					<Box sx={{ display: "flex", alignItems: "center", gap: 1 }} mb={2}>
						<Typography color={"rgba(255,255,255,0.5)"} fontSize={14} fontFamily={"Branding SemiBold"}>{`${
							currentStep?.type === "Recommandé" ? "Étape recommandée" : "Étape optionnelle"
						} `}</Typography>
						<Clock stroke={"rgba(255,255,255,0.5)"} />
						<Typography
							color={"rgba(255,255,255,0.5)"}
							fontSize={14}
							fontFamily={"Branding SemiBold"}
						>{` ${currentStep?.minutes} min`}</Typography>
					</Box>
				</Fade>
				{currentStep?.lienAudio && <StepPodcastPlayer key={currentStep?.id} currentStep={currentStep} />}
			</Box>
			<Box sx={{ display: "flex", flexDirection: "column", gap: 5 }}>
				{ressources?.map((bloc) => (
					<RenderContent key={bloc.id} bloc={bloc} />
				))}
			</Box>
		</Container>
	);
};

export default StepContentContainer;
