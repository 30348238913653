import React from "react";
import { OdysseeNavigationContext } from "./context";
import useOdyssee from "../odyssee/useOdyssee";
import { useSearchParams } from "react-router-dom";
import { useUpdateOdysseeStepMutation } from "../../store/api/odyssee";
import { useAuthToken } from "../../hooks/auth";

export const ODYSSEE_PAGES = { Main: "Main", Summary: "Summary", Step: "Step" };

const OdysseeNavigationContextProvider = ({ children }) => {
	const {
		modules,
		setCurrentModule,
		setCurrentStep,
		currentModuleIndex,
		progressStepIndexInModule,
		setSavedStepPosition,
		odyssee,
		databaseStepPosition,
		setDatabaseStepPosition,
		savedStepPosition,
		setCurrentPage,
		databaseModuleIndex,
		databaseStepIndex,
	} = useOdyssee();
	let [searchParams, setSearchParams] = useSearchParams();
	const [currentModuleId, setCurrentModuleId] = React.useState(null);
	const [currentStepId, setCurrentStepId] = React.useState(null);
	const [updateOdysseeStepMutation] = useUpdateOdysseeStepMutation();
	const authToken = useAuthToken();

	React.useEffect(() => {
		setCurrentModuleId(parseInt(searchParams.get("module")) || null);
		setCurrentStepId(parseInt(searchParams.get("step")) || null);
		const stepId = searchParams.get("step");
		if (stepId) {
			setSavedStepPosition(parseInt(stepId));
		}
	}, [searchParams]);

	React.useEffect(() => {
		if (!currentModuleId || !modules) return;
		const currentModule = modules?.find((item) => item.id === currentModuleId);
		setCurrentModule(currentModule);
		if (!!currentStepId) {
			const currentStep = currentModule?.lecon?.find((item) => item.id === currentStepId);
			setCurrentStep(currentStep);
		}
	}, [currentModuleId, modules, currentStepId]);

	const navigateToStep = (moduleId, stepId) => {
		window.scrollTo(0, 0);
		setSavedStepPosition(stepId);
		return setSearchParams({ module: moduleId, step: stepId });
	};

	const continueOdyssee = () => {
		window.scrollTo(0, 0);
		let nextStep = modules[databaseModuleIndex]?.lecon[databaseStepIndex];
		if (!nextStep) {
			nextStep = modules[0]?.lecon[0];
		}
		return setSearchParams({ module: nextStep?.idetape, step: nextStep?.id });
	};

	const navigateToNextStep = () => {
		let moveToSummary = false;
		let newModuleId = "";
		window.scrollTo(0, 0);
		let nextStep = modules[currentModuleIndex]?.lecon[progressStepIndexInModule + 1];
		if (!nextStep) {
			newModuleId = modules[currentModuleIndex + 1]?.id;
			nextStep = modules[currentModuleIndex + 1]?.lecon[0];
			moveToSummary = true;

			// return setSearchParams({ module: nextStep?.idetape, step: nextStep?.id });
		}
		if (!nextStep) return;
		if (savedStepPosition > 0) { //if (databaseStepPosition === savedStepPosition) {
			updateOdysseeStepMutation({
				odysseeUserId: odyssee?.idodysseeuser,
				authToken: authToken,
				etapeId: nextStep?.id,
			});
			setDatabaseStepPosition(nextStep?.id);
		}
		setSavedStepPosition(nextStep?.id);
		if (!!moveToSummary) {
			return setSearchParams({ module: newModuleId });
		}
		return setSearchParams({ module: nextStep?.idetape, step: nextStep?.id });
	};

	const navigateToPrevStep = () => {
		window.scrollTo(0, 0);
		let nextStep = modules[currentModuleIndex]?.lecon[progressStepIndexInModule - 1];
		if (!nextStep) {
			nextStep = modules[currentModuleIndex - 1]?.lecon[modules[currentModuleIndex - 1]?.lecon.length - 1];
		}
		if (!nextStep) return;
		setSavedStepPosition(nextStep?.id);
		return setSearchParams({ module: nextStep?.idetape, step: nextStep?.id });
	};

	const isFirstStep = React.useMemo(() => {
		let nextStep = modules?.[currentModuleIndex]?.lecon[progressStepIndexInModule - 1];
		if (!nextStep) {
			nextStep = modules?.[currentModuleIndex - 1]?.lecon[0];
		}
		return !nextStep;
	}, [currentModuleIndex, progressStepIndexInModule, modules]);

	const isLastStep = React.useMemo(() => {
		let nextStep = modules?.[currentModuleIndex]?.lecon[progressStepIndexInModule + 1];
		if (!nextStep) {
			nextStep = modules?.[currentModuleIndex + 1]?.lecon[0];
		}
		return !nextStep;
	}, [currentModuleIndex, progressStepIndexInModule, modules]);

	const navigateToSummary = (moduleId) => {
		window.scrollTo(0, 0);
		setSavedStepPosition(databaseStepPosition);
		return setSearchParams({ module: moduleId });
	};

	const navigateToMain = () => {
		window.scrollTo(0, 0);
		return setSearchParams({});
	};

	const currentPage = React.useMemo(() => {
		if (!!currentModuleId && !!currentStepId) {
			setCurrentPage(ODYSSEE_PAGES.Step);
			return ODYSSEE_PAGES.Step;
		}
		if (!!currentModuleId && !currentStepId) {
			setCurrentPage(ODYSSEE_PAGES.Summary);
			return ODYSSEE_PAGES.Summary;
		}
		setCurrentPage(ODYSSEE_PAGES.Main);
		return ODYSSEE_PAGES.Main;
	}, [currentStepId, currentModuleId]);

	const values = React.useMemo(
		() => ({
			currentPage,
			navigateToSummary,
			navigateToMain,
			navigateToStep,
			navigateToNextStep,
			navigateToPrevStep,
			isFirstStep,
			isLastStep,
			continueOdyssee,
		}),
		[
			currentPage,
			navigateToSummary,
			navigateToMain,
			navigateToStep,
			navigateToNextStep,
			navigateToPrevStep,
			isFirstStep,
			isLastStep,
			continueOdyssee,
		]
	);

	return <OdysseeNavigationContext.Provider value={values}>{children}</OdysseeNavigationContext.Provider>;
};

export default OdysseeNavigationContextProvider;
