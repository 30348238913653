import React from "react";
import { Box, styled, Typography } from "@mui/material";
import InexButton from "../../InexButton";
import Checkbox from "@mui/material/Checkbox";
import useOdysseeNavigation from "../../../context/odysseeNavigation/useOdyssee";
import { useIsMobile } from "../../../hooks/responsive";
import { GradientTypo } from "../../header/Header";
import { ReactComponent as FooterBackground } from "../../../resources/images/svg/odyssee/step-footer.svg";
import { ReactComponent as FooterBackgroundMobile } from "../../../resources/images/svg/odyssee/step-footer-mobile.svg";

const Container = styled(Box)(() => {
	return {
		backgroundColor: "#FFFFFF0F",
		padding: 20,
		marginLeft: "3vw",
		marginRight: "3vw",
		borderRadius: "20px",
		display: "flex",
	};
});

const CustomCheckBox = styled(Checkbox)(() => {
	return {
		color: "#ffffff",
		"&.Mui-checked": {
			color: "#ffffff",
		},
	};
});

const StepFooter = () => {
	const [checked, setChecked] = React.useState(false);
	const { navigateToNextStep, navigateToPrevStep, isFirstStep, isLastStep, navigateToMain } = useOdysseeNavigation();
	const isMobile = useIsMobile();

	const handleChange = (event) => {
		setChecked(event.target.checked);
	};

	if (isMobile) {
		return (
			<Container sx={{ flexDirection: "column" }}>
				<Box
					sx={{
						display: "flex",
						alignItems: "flex-start",
						position: "relative",
						minHeight: 160,
					}}
				>
					<Box
						sx={{
							position: "absolute",
							right: -20,
							left: -20,
							bottom: -20,
							top: -20,
							pointerEvents: "none",
						}}
					>
						<FooterBackgroundMobile style={{ width: "100%", borderTopRightRadius: "20px" }} />
					</Box>
					<GradientTypo mt={1} fontSize={20} sx={{}}>
						Vous avez fait un pas de plus dans
						<br /> votre odyssée...
					</GradientTypo>
				</Box>
				<Box sx={{ display: "flex", justifyContent: "center", flexWrap: "wrap", alignItems: "center", gap: 3 }}>
					<Box sx={{ display: "flex", alignItems: "center" }}>
						<CustomCheckBox onChange={handleChange} checked={checked} />
						<Typography fontFamily={"Branding Medium"} color={"#ffffff"} fontSize={15}>
							Enregistrer mon odyssée ici, et…
						</Typography>
					</Box>

					<InexButton
						sx={{ backgroundColor: "#C7C4DE", width: "100%", opacity: isFirstStep ? 0.4 : 1 }}
						textWithGradient={false}
						text={"Retourner à l’étape précédente "}
						onClick={() => {
							setChecked(false);
							navigateToPrevStep();
						}}
						textSx={{ color: "#182B43" }}
						disabled={isFirstStep}
					/>
					<InexButton
						sx={{
							backgroundColor: "#EF7D8F",
							width: "100%",
							opacity: checked && !isLastStep ? 1 : 0.3,
							transition: "opacity 0.3s ease box-shadow 0.3s ease, transform 0.3s ease",
							"&:hover": {
								boxShadow: "0 0 20px 10px rgba(230, 138, 150, 0.5)",
								backgroundColor: "#EF7D8F",
								border: "none",
							},
						}}
						onClick={() => {
							setChecked(false);
							navigateToNextStep();
						}}
						textWithGradient={false}
						text={"Découvrir l’étape suivante"}
						textSx={{ color: "#FFFFFF", background: "transparent" }}
						disabled={isLastStep}
					/>
				</Box>
			</Container>
		);
	}

	return (
		<Container sx={{ height: "auto" }}>
			<Box
				sx={{
					width: "50%",
					display: "flex",
					alignItems: "center",
					position: "relative",
				}}
			>
				<Box sx={{ position: "absolute", right: -20, left: -20, bottom: -20, top: -20, pointerEvents: "none" }}>
					<FooterBackground style={{ height: "100%" }} />
				</Box>
				<GradientTypo fontSize={20} sx={{}}>
					Vous avez fait un pas de plus dans
					<br /> votre odyssée...
				</GradientTypo>
			</Box>

			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					flexWrap: "wrap",
					flexDirection: "column",
					width: "50%",
					alignItems: "flex-end",
					gap: 2,
				}}
			>
				<Box sx={{ display: "flex", alignItems: "center" }}>
					<CustomCheckBox onChange={handleChange} checked={checked} />
					<Typography fontFamily={"Branding Medium"} color={"#ffffff"} fontSize={15}>
						Enregistrer mon odyssée ici, et…
					</Typography>
				</Box>

				<Box
					sx={{ display: "flex", alignItems: "center", gap: 3, flexWrap: "wrap", justifyContent: "flex-end" }}
				>
					<InexButton
						sx={{ backgroundColor: "#C7C4DE", width: 270, opacity: isFirstStep ? 0.4 : 1 }}
						textWithGradient={false}
						text={"Retourner à l’étape précédente "}
						onClick={() => {
							setChecked(false);
							navigateToPrevStep();
						}}
						textSx={{ color: "#182B43" }}
						disabled={isFirstStep}
					/>
					<InexButton
						sx={{
							backgroundColor: "#EF7D8F",
							width: 270,
							opacity: checked ? 1 : 0.3,
							transition: "opacity 0.3s ease box-shadow 0.3s ease, transform 0.3s ease",
							"&:hover": {
								boxShadow: "0 0 20px 10px rgba(230, 138, 150, 0.5)",
								backgroundColor: "#EF7D8F",
								border: "none",
							},
						}}
						onClick={() => {
							if (isLastStep) {
								navigateToMain();
								return;
							}
							setChecked(false);
							navigateToNextStep();
						}}
						disableBackground={true}
						textWithGradient={false}
						text={isLastStep ? "Terminer" : "Découvrir l’étape suivante"}
						textSx={{ color: "#FFFFFF", background: "transparent" }}
					/>
				</Box>
			</Box>
		</Container>
	);
};

export default StepFooter;
