import React from "react";
import { Box, styled, Typography } from "@mui/material";
import InexButton from "../../../InexButton";
import { useIsMobile } from "../../../../hooks/responsive";

const BoxContainer = styled(Box)(({ theme }) => ({
	backgroundColor: "rgba(24,18,45,0.95)",
	zIndex: 2,
}));

const Article = ({ webContent, handleClose }) => {
	const isMobile = useIsMobile();

	return (
		<BoxContainer sx={{ backgroundColor: "rgba(24,18,45,0.95)" }}>
			<Box
				sx={{
					display: "flex",
					gap: 3,
					alignItems: "stretch",
					zIndex: 2,
					position: "relative",
					padding: isMobile ? 3 : 5,
				}}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-evenly",
						flexDirection: "column",
						gap: 3,
					}}
				>
					<Typography
						dangerouslySetInnerHTML={{ __html: webContent?.titre }}
						textAlign={"center"}
						color={"#ffffff"}
						fontFamily={"Branding Bold"}
						fontSize={30}
					></Typography>
					<Box
						sx={{
							backgroundColor: "#FFFFFF33",
							borderRadius: "10px",
							padding: "20px",
							marginBottom: 3,
							marginTop: 2,
						}}
					>
						<Typography
							dangerouslySetInnerHTML={{ __html: webContent?.minidesc }}
							color={"#ffffff"}
							fontSize={16}
							textAlign={"center"}
						/>
					</Box>
					{isMobile ? (
						<Box sx={{ display: "flex", gap: 4, flexDirection: "column" }}>
							<img
								src={webContent?.imageHome || webContent?.image}
								style={{
									height: 250,
									position: "relative",
									width: "auto",
								}}
							/>
							<Typography
								dangerouslySetInnerHTML={{ __html: webContent?.contenu }}
								color={"#ffffff"}
							></Typography>
						</Box>
					) : (
						<Box sx={{ display: "block" }}>
							<img
								src={webContent?.imageHome || webContent?.image}
								style={{
									height: 250,
									float: "right",
									margin: "0 0 50px 100px",
									maxWidth: "40%",
									position: "relative",
									width: "270px",
								}}
							/>
							<Typography
								dangerouslySetInnerHTML={{ __html: webContent?.contenu }}
								color={"#ffffff"}
							></Typography>
						</Box>
					)}
					<InexButton
						onClick={handleClose}
						variant={"text"}
						textWithGradient={false}
						text={"Fermer"}
						textSx={{
							color: "white",
						}}
						sx={{
							backgroundColor: "#EF7D8F",
							width: 230,
							alignSelf: "center",
						}}
					/>
				</Box>
			</Box>
		</BoxContainer>
	);
};

export default Article;
