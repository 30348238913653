import React from "react";
import { Box, styled, Typography } from "@mui/material";
import { ReactComponent as Clock } from "../../../resources/images/svg/odyssee/clock.svg";
import useOdyssee from "../../../context/odyssee/useOdyssee";
import moment from "moment";
import { useIsMobile } from "../../../hooks/responsive";

const Card = styled(Box)(() => {
	return {
		backgroundColor: "#FFFFFF0F",
		borderRadius: 20,
		position: "absolute",
		backdropFilter: "blur(9px)",
	};
});

const SummaryCard = () => {
	const { odyssee, currentModule, progressModuleIndex } = useOdyssee();
	const isMobile = useIsMobile();

	const totalDuration = React.useMemo(() => {
		return currentModule?.lecon?.reduce(
			(acc, value) => {
				if (!value.id) return acc;
				if (value.type === "Recommandé") {
					acc.recommended += value?.minutes || 0;
				} else {
					acc.optional += value?.minutes || 0;
				}
				return acc;
			},
			{ recommended: 0, optional: 0 }
		);
	}, [currentModule]);

	const recommendedTime = React.useMemo(() => {
		const duration = moment.duration(totalDuration?.recommended, "minutes");

		return duration.asMinutes() < 60
			? `${duration.minutes()} min`
			: `${Math.floor(duration.asHours())}h${duration.minutes()}`;
	}, [totalDuration]);

	const totalTime = React.useMemo(() => {
		const duration = moment.duration(totalDuration?.recommended + totalDuration?.optional, "minutes");

		return duration.asMinutes() < 60
			? `${duration.minutes()} min`
			: `${Math.floor(duration.asHours())}h${duration.minutes()}`;
	}, [totalDuration]);

	const width = React.useMemo(() => {
		return isMobile ? "95%" : 600;
	});

	return (
		<Box sx={{ position: "relative", width: width }}>
			{!isMobile && (
				<React.Fragment>
					<Card sx={{ width: width, height: "calc(100% - 100px)", transform: "translate(0px, 50px)" }}></Card>
					<Card
						sx={{ width: width - 50, height: "calc(100% - 50px)", transform: "translate(25px, 25px)" }}
					></Card>
				</React.Fragment>
			)}
			<Card
				sx={{
					width: isMobile ? width : width - 100,
					height: "auto",
					transform: !isMobile && "translate(50px, 0px)",
					position: "relative",
					marginLeft: isMobile && "5%",
				}}
				p={4}
			>
				<Box
					sx={{
						background: "#FFFFFF1A",
						display: "flex",
						borderRadius: "50px",
						padding: "10px 15px",
						gap: 1,
						width: "fit-content",
					}}
				>
					{/*<Typography fontFamily={"Branding Medium"} color={"#FFFFFF"}>*/}
					{/*	Module A*/}
					{/*</Typography>*/}
					<Box sx={{ display: "flex", alignItems: "center" }}>
						<Typography fontFamily={"Branding Medium"} color={"#FFFFFF"}>
							Module {String.fromCharCode(65 + progressModuleIndex)}
						</Typography>{" "}
						<Clock stroke={"white"} style={{ marginLeft: 10, marginRight: 7 }} />
						<Typography fontFamily={"Branding Medium"} color={"#FFFFFF"}>
							entre {recommendedTime} et {totalTime}
						</Typography>
					</Box>
				</Box>
				<Typography
					color={"#FFFFFF"}
					fontFamily={"branding Medium"}
					fontSize={15}
					mt={2}
					dangerouslySetInnerHTML={{ __html: currentModule?.description }}
				/>
			</Card>
		</Box>
	);
};

export default SummaryCard;
