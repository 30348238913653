import React from "react";
import { Grid, styled, Typography } from "@mui/material";
import moment from "moment";
import { SUBSCRIPTIONS_TYPES } from "../../../routes/Abonnement";

const Title = styled(Typography)(({ theme }) => {
	return {
		color: "rgba(22,42,65,0.4)",
		fontFamily: "Branding Medium",
		fontWeight: "500",
		fontSize: 12,
	};
});

const ItemValue = styled(Typography)(({ theme }) => {
	return {
		color: theme.palette.secondary.main,
		fontFamily: "Branding Bold",
		fontSize: 16,
	};
});

const AbonnementBoxContent = ({ subscription, emptyContent, subscriptionType }) => {
	const _dateRenouvellement = React.useMemo(() => {
		if (!subscription?.daterenouv) return null;
		moment.locale("fr");
		const rnum =
			subscription.daterenouv < Math.floor(Date.now() / 1000)
				? 0
				: Math.trunc((subscription.daterenouv - Math.floor(Date.now() / 1000) - 3888000) / 7776000);
		if (subscriptionType === SUBSCRIPTIONS_TYPES.Mag && !(subscription.typerenouv > 0))
			return rnum + " numéro(s) à recevoir";
		return "Le " + moment((subscription.daterenouv - 420000) * 1000).format("DD MMMM YYYY");
	}, [subscription]);

	if (subscription && subscription?.montant && subscription?.daterenouv > 10000) {
		return (
			<Grid
				container
				rowSpacing={2}
				sx={{ marginTop: (theme) => theme.spacing(1), marginBottom: (theme) => theme.spacing(2) }}
			>
				<Grid item xs={6}>
					<Title variant={"span"}>Votre formule</Title>
					<ItemValue>
						{subscriptionType === SUBSCRIPTIONS_TYPES.Tv
							? subscription.typeabo === "Mensuel"
								? "9,90"
								: "99,00"
							: subscription.montant / 100}{" "}
						€
					</ItemValue>
				</Grid>
				<Grid item xs={6}>
					<Title variant={"span"}>Fréquence de paiement</Title>
					<ItemValue>{subscription.typeabo}</ItemValue>
				</Grid>
				{subscription.typepaiement && (
					<Grid item xs={6}>
						<Title variant={"span"}>Mode de paiement</Title>
						<ItemValue>{subscription.typepaiement}</ItemValue>
					</Grid>
				)}
				<Grid item xs={6}>
					<Title variant={"span"}>
						{subscriptionType === SUBSCRIPTIONS_TYPES.Mag && !(subscription.typerenouv > 0)
							? "Il vous reste"
							: subscription.typerenouv > 0 ? "Date de renouvellement" : "Date de validité"}
					</Title>
					<ItemValue>{_dateRenouvellement}</ItemValue>
				</Grid>
				<Grid item xs={6}>
					<Title variant={"span"}>Renouvellement automatique</Title>
					<ItemValue>{subscription.typerenouv > 0 ? "Oui" : "Non"}</ItemValue>
				</Grid>
			</Grid>
		);
	}

	const prices = ["2,90", "9,90", "3,00"];

	return (
		<Grid
			flex={1}
			container
			sx={{ marginTop: (theme) => theme.spacing(2) }}
			alignItems={"flex-end"}
			columnSpacing={2}
		>
			{subscriptionType === SUBSCRIPTIONS_TYPES.Mag && (
				<Grid item xs={5}>
					<img style={{ maxWidth: "90%" }} src={"https://medias.inrees.com/img/magazine/last_home.jpg"} />
				</Grid>
			)}
			<Grid item xs={5}>
				<Title variant={"span"}>Formule à partir de</Title>
				<Typography
					variant={"span"}
					sx={{
						fontSize: 28,
						fontFamily: "Branding Bold",
						color: (theme) => theme.palette.secondary.main,
						display: "flex",
						alignItems: "flex-end",
					}}
				>
					{prices[subscriptionType - 1]}€
					<Typography
						variant={"span"}
						sx={{
							fontFamily: "Branding Bold",
							fontSize: 14,
							marginBottom: "5px",
							marginLeft: "7px",
						}}
					>
						/ mois
					</Typography>
				</Typography>
			</Grid>
		</Grid>
	);
};

export default AbonnementBoxContent;
