import { Box, styled } from "@mui/material";
import ProgressOdyssee from "./ProgressOdyssee";
import InexButton from "../../InexButton";
import SummaryCarousel from "./SummaryCarousel";
import React from "react";
import useOdyssee from "../../../context/odyssee/useOdyssee";
import useOdysseeNavigation from "../../../context/odysseeNavigation/useOdyssee";
import { ReactComponent as Ovni } from "../../../resources/images/svg/odyssee/ovni.svg";
import { useIsMobile } from "../../../hooks/responsive";

const ImageContainer = styled(Box, { shouldForwardProp: (prop) => prop !== "isActive" && prop !== "isDone" })(
	({ imageLoaded, isMobile }) => {
		return {
			maxHeight: 650,
			opacity: +imageLoaded,
			transition: "opacity 0.3s ease",
			position: "relative",
			width: isMobile ? "100%" : "unset",
			display: "flex",
		};
	}
);

const Home = () => {
	const [imageLoaded, setImageLoaded] = React.useState(false);
	const isMobile = useIsMobile();

	const { odyssee, savedStepPosition } = useOdyssee();
	const { continueOdyssee } = useOdysseeNavigation();

	return (
		<React.Fragment>
			<Box sx={{ display: "flex", position: "relative", top: isMobile ? 20 : -20, minHeight: 700 }}>
				<ImageContainer>
					<img
						style={{
							maxHeight: 650,
							width: isMobile ? "100%" : "unset",
							opacity: +imageLoaded,
							...(isMobile && { objectFit: "cover" }),
						}}
						onLoad={() => setImageLoaded(true)}
						src={`https://medias.inrees.com/img/odyssees/1_${odyssee?.id}.jpg`}
					/>
					{isMobile && (
						<Box
							sx={{
								position: "absolute",
								backgroundColor: "rgba(24,18,45,0.35)",
								top: 0,
								left: 0,
								right: 0,
								bottom: 0,
								opacity: +imageLoaded,
							}}
						/>
					)}
				</ImageContainer>
				>
				<Box
					sx={{
						position: isMobile ? "absolute" : "relative",
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						gap: 8,
						opacity: +imageLoaded,
						transition: "opacity 0.3s ease",
						...(isMobile && { top: 0, left: 0, right: 0, bottom: 0 }),
					}}
				>
					<ProgressOdyssee />
					<InexButton
						startIcon={<Ovni />}
						onClick={continueOdyssee}
						variant={"text"}
						textWithGradient={false}
						text={savedStepPosition === 0 ? "Commencer l'odyssée" : "Poursuivre l'odyssée"}
						textSx={{
							color: "white",
						}}
						sx={{
							backgroundColor: "#EF7D8F",
							width: 230,
						}}
					/>
				</Box>
				<Box
					sx={{
						position: "absolute",
						bottom: 0,
						left: 0,
						right: 0,
						height: 50,
						background: "linear-gradient(360deg, #18122D 10.94%, rgba(24, 18, 45, 0) 75%)",
					}}
				/>
			</Box>
			<Box sx={{ width: "100%", position: "relative", top: isMobile ? 0 : -90 }}>
				<SummaryCarousel />
			</Box>
		</React.Fragment>
	);
};

export default Home;
