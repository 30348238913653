import { Howl, Howler } from "howler";
import { Box, styled, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as Play } from "../../../resources/images/svg/odyssee/play-podcast.svg";
import { ReactComponent as Pause } from "../../../resources/images/svg/odyssee/pause-podcast.svg";

const StartPodcastContainer = styled(Box, { shouldForwardProp: (prop) => prop !== "isStarted" })(({ isStarted }) => {
	return {
		height: 40,
		width: "auto",
		backgroundColor: "#1D73FF",
		borderRadius: "50px",
		display: "flex",
		alignItems: "center",
		padding: "0 20px",
		gap: 10,
		cursor: "pointer",
		transition: "box-shadow 0.3s ease",
		maxWidth: "70%",
		...(isStarted
			? {}
			: {
					"&:hover": {
						boxShadow: "0 0 20px 10px rgba(29, 115, 255, 0.5)",
						backgroundColor: "#1D73FF",
						border: "none",
					},
			  }),
	};
});

const ButtonContainer = styled(Box)(() => {
	return {
		backgroundColor: "rgba(255,255,255,0.4)",
		width: 22,
		height: 22,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: 11,
	};
});

const ProgressBar = styled(Box)(() => {
	return {
		backgroundColor: "rgba(255,255,255,0.2)",
		width: 400,
		height: 10,
		position: "relative",
		borderRadius: "6px",
	};
});
const ProgressBarValue = styled(Box)(() => {
	return {
		backgroundColor: "rgba(255,255,255,1)",
		height: 10,
		borderRadius: "6px",
		position: "absolute",
		left: 0,
		bottom: 0,
		top: 0,
	};
});

const parseTimer = (time) => {
	const minutes = Math.floor(time / 60);
	const seconds = time - minutes * 60;
	return `${String(minutes.toFixed(0)).padStart(2, "0")}:${String(seconds.toFixed(0)).padStart(2, "0")}`;
};

const StepPodcastPlayer = ({ currentStep }) => {
	const [isPlaying, setIsPlaying] = React.useState(false);
	const [isStarted, setIsStarted] = React.useState(false);
	const [position, setPosition] = React.useState(0);
	const podcastRef = React.useRef(null);
	const containerRef = React.useRef(null);
	const [containerWidth, setContainerWidth] = React.useState(0);
	const [progressWidth, setProgressWidth] = React.useState(0);

	React.useEffect(() => {
		const updateWidth = () => {
			if (containerRef.current) {
				setContainerWidth(containerRef.current.offsetWidth);
			}
		};
		updateWidth();
		window.addEventListener("resize", updateWidth);
		return () => {
			window.removeEventListener("resize", updateWidth);
		};
	}, [isStarted]);

	React.useEffect(() => {
		if (currentStep) {
			podcastRef.current = new Howl({
				src: [currentStep?.lienAudio],
				volume: 0.5,
			});
			podcastRef.current.on("end", function () {
				setIsPlaying(false);
			});
		}
		return () => {
			podcastRef.current?.unload();
		};
	}, [currentStep]);

	React.useEffect(() => {
		let interval;
		if (isPlaying) {
			interval = setInterval(() => {
				if (podcastRef.current) {
					const localPosition = podcastRef.current.seek();
					setPosition(localPosition);
					const progressPercentage = (localPosition * 100) / podcastRef.current?.duration();
					setProgressWidth((progressPercentage * containerWidth) / 100);
				}
			}, 100); // Met à jour toutes les 500 ms
		}
		return () => clearInterval(interval); // Nettoyage de l'intervalle
	}, [isPlaying, containerWidth]);

	const togglePlay = () => {
		if (!isPlaying) {
			setIsPlaying(true);
			setIsStarted(true);
			podcastRef.current?.play();
		} else {
			setIsPlaying(false);
			podcastRef.current?.pause();
		}
	};

	const onProgressClicked = (event) => {
		const positionClicked = event.nativeEvent.offsetX;
		const percent = (positionClicked * 100) / containerWidth;
		const time = (percent * podcastRef.current?.duration()) / 100;
		podcastRef.current?.seek(time);
		setPosition(time);
		setProgressWidth(positionClicked);
	};

	const onContainerClick = () => {
		if (!isStarted) {
			setIsPlaying(true);
			setIsStarted(true);
			podcastRef.current?.play();
		}
	};

	return (
		<StartPodcastContainer isStarted={isStarted} onClick={onContainerClick}>
			<ButtonContainer onClick={togglePlay}>{!isPlaying ? <Play /> : <Pause />}</ButtonContainer>
			{isStarted ? (
				<Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
					<Typography sx={{ width: 40 }} color={"#FFFFFF"} fontFamily={"Branding SemiBold"}>
						{parseTimer(position)}
					</Typography>
					<ProgressBar onClick={onProgressClicked} ref={containerRef}>
						<ProgressBarValue sx={{ width: `${progressWidth}px` }} />
					</ProgressBar>
					<Typography sx={{ width: 40 }} color={"#FFFFFF"} fontFamily={"Branding SemiBold"}>
						{parseTimer(podcastRef.current.duration())}
					</Typography>
				</Box>
			) : (
				<Typography fontFamily={"branding SemiBold"} color={"#FFFFFF"}>
					Écouter la version audio de l’étape
				</Typography>
			)}
		</StartPodcastContainer>
	);
};

export default StepPodcastPlayer;
