import { Box, Dialog, styled, Typography } from "@mui/material";
import React from "react";
import { useGetOdysseeWebRessourceQuery } from "../../../../store/api/odyssee";
import { useAuthToken } from "../../../../hooks/auth";
import { useParams } from "react-router";
import Film from "./Film";
import Article from "./Article";
import Podcast from "./Podcast";
import Video from "./Video";
import Collection from "./Collection";
import CloseIcon from "../../../../resources/images/svg/close-white.svg";
import { useIsMobile } from "../../../../hooks/responsive";
import VideoPreview from "./VideoPreview";
import ArticlePreview from "./ArticlePreview";
import PodcastPreview from "./PodcastPreview";
import CollectionPreview from "./CollectionPreview";
import ProductPreview from "./ProductPreview";

const Container = styled(Box)(({ theme }) => {
	return {
		display: "flex",
		gap: 2,
		alignItems: "center",
		transition: "all 0.3s",
		position: "relative",
		justifyContent: "center",
		// borderRadius: 8,
		// paddingTop: 8,
		// paddingBottom: 8,
		// paddingLeft: 8,
		// paddingRight: 8,
		// backgroundColor: computeHexOpacity(lightBlue[50], 10),
		width: "100%",
		"&:hover": {
			// backgroundColor: computeHexOpacity(lightBlue[50], 30),
		},
	};
});

const CATEGORIES = {
	video: {
		name: "Vidéo",
		variant: "contained",
		color: "primary",
		url: "https://tv.inexplore.com/video/",
		urlKey: "url",
		modalWidth: "lg",
	},
	article: {
		name: "Article",
		variant: "contained",
		color: "success",
		url: "https://www.inexplore.com/articles/",
		urlKey: "url",
		modalWidth: "lg",
	},
	podcast: {
		name: "Podcast",
		variant: "contained",
		color: "warning",
		url: "https://www.inexplore.com/podcasts/",
		urlKey: "url",
		modalWidth: "lg",
	},
	collection: {
		name: "Collection de livres",
		variant: "contained",
		color: "error",
		url: "https://www.inexplore.com/",
		urlKey: "url",
		modalWidth: "xl",
	},
	collectionFilms: {
		name: "Collection de films",
		variant: "contained",
		color: "secondary",
		url: "https://www.inexplore.com/",
		urlKey: "url",
		modalWidth: "xl",
	},
	film: { name: "Film", variant: "outlined", color: "info", url: "https://www.inexplore.com/film/", urlKey: "id" },
	livre: {
		name: "Livre",
		variant: "outlined",
		color: "primary",
		url: "https://www.inexplore.com/livre/",
		urlKey: "id",
		modalWidth: "lg",
	},
	produit: {
		name: "Produit",
		variant: "outlined",
		color: "error",
		url: "https://boutique.inexplore.com/produit/",
		urlKey: "id",
		modalWidth: "lg",
	},
};

const WebContentDisplay = React.forwardRef(({ block, webContent, setModalOpen }, ref) => {
	React.useImperativeHandle(
		ref,
		() => {
			return {
				openLink() {
					openWebContentPreview();
				},
			};
		},
		[]
	);
	const isMobile = useIsMobile();

	const meta = React.useMemo(() => {
		if (block?.meta && block?.meta !== "") return JSON.parse(block.meta);
		return null;
	}, [block?.meta]);

	const url = React.useMemo(() => {
		if (meta?.item?.url && meta?.category) {
			return CATEGORIES[meta.category]?.url + meta.item[CATEGORIES[meta.category]?.urlKey];
		}
		return null;
	}, [meta]);

	const openWebContentPreview = () => {
		window.open(url, "_blank").focus();
	};

	const layoutHorizontal = React.useMemo(() => {
		return (!!webContent?.description || !!webContent?.contenu) && !isMobile;
	}, [webContent]);

	const RenderContent = () => {
		switch (meta.category) {
			case "film":
				return <VideoPreview webContent={webContent} setModalOpen={setModalOpen} />;
			case "article":
				return (
					<ArticlePreview
						layoutHorizontal={layoutHorizontal}
						webContent={webContent}
						setModalOpen={setModalOpen}
					/>
				);
			case "podcast":
				return <PodcastPreview webContent={webContent} setModalOpen={setModalOpen} />;
			case "video":
				return <VideoPreview webContent={webContent} setModalOpen={setModalOpen} />;
			case "collection":
				return <CollectionPreview webContent={webContent} setModalOpen={setModalOpen} />;
			case "collectionFilms":
				return <CollectionPreview webContent={webContent} category={"films"} setModalOpen={setModalOpen} />;
			case "produit":
				return <ProductPreview layoutHorizontal={layoutHorizontal} webContent={webContent} />;
			default:
				return <Film webContent={webContent} setModalOpen={setModalOpen} />;
		}
	};

	return RenderContent();

	return (
		<Box
			sx={{
				display: "flex",
				gap: 3,
				alignItems: "stretch",
				width: "fit-content",
				flexDirection: layoutHorizontal ? "row" : "column",
				minWidth: 300,
			}}
		>
			<img
				src={webContent?.imageHome || webContent?.image}
				style={{ height: 250, width: "fit-content", alignSelf: "center" }}
			/>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
					alignItems: layoutHorizontal ? "flex-start" : "center",
				}}
				py={1}
				gap={2}
			>
				{(!!webContent?.titre || !!webContent?.description || !!webContent?.contenu) && (
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-start",
							flexDirection: "column",
							gap: 3,
							marginRight: layoutHorizontal ? 4 : 0,
							alignItems: layoutHorizontal ? "flex-start" : "center",
						}}
					>
						<Typography
							color={"#ffffff"}
							fontFamily={"Yeseva One"}
							fontSize={20}
							dangerouslySetInnerHTML={{ __html: webContent?.titre }}
						></Typography>
						<Typography
							color={"#ffffff"}
							fontFamily={"Branding SemiBold"}
							sx={{
								display: "-webkit-box",
								overflow: "hidden",
								WebkitBoxOrient: "vertical",
								WebkitLineClamp: 3,
							}}
							dangerouslySetInnerHTML={{ __html: webContent?.description || webContent?.contenu }}
						></Typography>
					</Box>
				)}
				<Box
					sx={{
						border: "1px solid #ffffff",
						width: "fit-content",
						padding: "3px 10px",
						borderRadius: "30px",
					}}
				>
					<Typography color={"#ffffff"} fontFamily={"Branding SemiBold"}>
						{CATEGORIES[meta.category].name}
					</Typography>
				</Box>
			</Box>
		</Box>
	);
});

const WebContentModal = ({ open, handleClose, webContent, category }) => {
	const RenderContent = () => {
		switch (category) {
			case "film":
				return <Film webContent={webContent} />;
			case "article":
				return <Article webContent={webContent} handleClose={handleClose} />;
			case "podcast":
				return <Podcast webContent={webContent} />;
			case "video":
				return <Video webContent={webContent} />;
			case "collection":
				return <Collection webContent={webContent} />;
			case "collectionFilms":
				return <Collection webContent={webContent} category={"films"} />;
			default:
				return <Film webContent={webContent} />;
		}
	};

	const disableBoxShadow = React.useMemo(() => {
		return category === "";
	}, [category]);

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			fullWidth={true}
			maxWidth={CATEGORIES[category].modalWidth}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			PaperProps={{
				style: {
					backgroundColor: "transparent",
					boxShadow: disableBoxShadow ? "none" : "0 0 30px 5px rgb(255, 255, 255, 0.6)",
					borderRadius: 10,
				},
			}}
			slotProps={{
				backdrop: {
					sx: {
						backgroundColor: "rgba(0, 0, 0, 0.9)",
					},
				},
			}}
		>
			<Box
				sx={{
					backgroundColor: "rgba(255,255,255,0.4)",
					width: 40,
					height: 40,
					position: "absolute",
					right: 10,
					top: 10,
					borderRadius: "50%",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					cursor: "pointer",
					zIndex: 10,
				}}
				onClick={handleClose}
			>
				<img style={{ width: "25px" }} src={CloseIcon} alt={"burger-menu"} />
			</Box>
			{RenderContent()}
		</Dialog>
	);
};

const WebContent = ({ block }) => {
	const previewRef = React.useRef(null);
	const authToken = useAuthToken();
	const { odysseeId } = useParams();
	const [modalOpen, setModalOpen] = React.useState(false);

	const meta = React.useMemo(() => {
		if (block?.meta && block?.meta !== "") return JSON.parse(block.meta);
		return null;
	}, [block?.meta]);

	const { data: webContent } = useGetOdysseeWebRessourceQuery(
		{ odysseeId, authToken, categorie: meta?.category, ressourceId: meta?.item.id },
		{ skip: !authToken || !meta }
	);

	if (!block?.meta) {
		return null;
	}

	const handleModalClose = (event) => {
		event.stopPropagation();
		setModalOpen(false);
	};

	const openPreview = () => {
		setModalOpen(true);
	};

	return (
		<Container>
			{!!webContent && (
				<WebContentDisplay ref={previewRef} block={block} webContent={webContent} setModalOpen={setModalOpen} />
			)}
			<WebContentModal
				open={modalOpen}
				handleClose={handleModalClose}
				webContent={webContent}
				category={meta.category}
			/>
		</Container>
	);
};

export default WebContent;
