import React from "react";

import {
	Document,
	Page,
	View,
	Text,
	Link,
	Font,
	StyleSheet,
	PDFViewer,
	Image,
	PDFDownloadLink,
} from "@react-pdf/renderer";
import { useLazyGetOrderQuery } from "../store/api/orders";

import BrandingSemiBold from "../resources/fonts/branding-2-cufonfonts/Branding Semibold.otf";
import BrandingBold from "../resources/fonts/branding-2-cufonfonts/Branding Bold.otf";
import BrandingMedium from "../resources/fonts/branding-2-cufonfonts/Branding Medium.otf";
import InvoiceHeader from "../components/pdfInvoice/InvoiceHeader";
import { useSelector } from "react-redux";
import { getUser } from "../store/reducers/authSlice";
import InvoiceFooter from "../components/pdfInvoice/InvoiceFooter";
import InvoiceContent from "../components/pdfInvoice/InvoiceContent";
import GradientLine from "../components/pdfInvoice/GradientLine";
import { Alert, Box, CircularProgress, Typography } from "@mui/material";
import Invoice from "../components/pdfInvoice/Invoice";
import InexButton from "../components/InexButton";
import { useNavigate } from "react-router-dom";
import withAuth from "../HOC/withAuth";
import { useLazyGetUserAddressesQuery } from "../store/api/user";
import SmartsuppChat from "../components/SmartsuppChat";

Font.register({
	family: "Branding",
	fonts: [
		{
			src: BrandingMedium,
		},
		{
			src: BrandingBold,
			fontWeight: "bold",
		},
		{
			src: BrandingSemiBold,
			fontWeight: "semibold",
		},
	],
});

const SeeOrder = () => {
	const user = useSelector(getUser);
	const [orderNotFound, setOrderNotFound] = React.useState(false);
	const [order, setOrder] = React.useState(null);

	const [getOrder, { isFetching }] = useLazyGetOrderQuery();
	const [address, setAddress] = React.useState(null);

	React.useEffect(() => {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		if (urlParams.has("id") && user) {
			try {
				const orderId = JSON.parse(urlParams.get("id"));
				getOrder({
					idcommande: orderId,
				}).then(({ data }) => {
					if (data && !!data.commande) {
						setOrder(data.commande);
						if (urlParams.has("address")) {
							setAddress(JSON.parse(urlParams.get("address")));
						} else {
							setAddress(data.commande.adresse);
						}
					}
					else setOrderNotFound(true);
				});
			} catch (e) {
				console.log(e);
				setOrderNotFound(true);
			}
		}
	}, [user]);

	React.useEffect(() => {
		document.body.style.overflow = "hidden";
	}, []);

	const navigate = useNavigate();

	return (
		<Box
			sx={{
				position: "fixed",
				top: 0,
				left: 0,
				width: "100vw",
				height: "100vh",
				zIndex: 10000,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				backgroundColor: "rgb(72,76,79)",
			}}
		>
			{!order ? (
				orderNotFound === true ? (
					<Alert severity={"error"} variant={"filled"} icon={false}>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								flexDirection: "column",
							}}
						>
							<Typography fontSize={20} mb={2}>
								La commande est introuvable
							</Typography>
							<InexButton
								onClick={() => {
									navigate("/commandes");
								}}
								variant={"text"}
								textWithGradient={false}
								text={"Retourner à mes commandes"}
								textSx={{
									color: "white",
								}}
								sx={{
									width: "100%",
									background: "transparent",
									borderColor: "white",
									"&:hover": {
										borderColor: (theme) => "white",
										background: (theme) => "#fff4",
									},
								}}
							/>
						</Box>
					</Alert>
				) : (
					<CircularProgress
						size={100}
						sx={{
							color: "rgb(44, 165, 214)",
						}}
					/>
				)
			) : (
				<PDFViewer
					fileName={`Facture Inexploré${order?.reference ? ` ${order.reference}` : ""}`}
					style={{
						width: "100%",
						height: "100%",
					}}
				>
					<Invoice order={order} address={address} />
				</PDFViewer>
			)}
			{/* Intégration de Smartsupp */}
			<SmartsuppChat />
		</Box>
	);
};

export default withAuth(SeeOrder);
