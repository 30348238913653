import { Box, Typography } from "@mui/material";
import React from "react";
import InexButton from "../../../InexButton";
import { ShoppingBagOutlined } from "@mui/icons-material";
import { useIsMobile } from "../../../../hooks/responsive";

const ProductPreview = ({ webContent, layoutHorizontal }) => {
	const isMobile = useIsMobile();

	const openShop = () => {
		window.open(`https://boutique.inexplore.com/panier?id=${webContent?.id}&quantity=1`, "_blank").focus();
	};

	return (
		<Box
			sx={{
				display: "flex",
				gap: 3,
				alignItems: "stretch",
				width: "fit-content",
				flexDirection: layoutHorizontal ? "row" : "column",
				minWidth: 300,
				backgroundColor: "#18122D",
				padding: "40px 30px",
				borderRadius: "10px",
				alignSelf: "center",
			}}
		>
			<img
				src={webContent?.imageHome || webContent?.image}
				style={{
					height: 250,
					width: "fit-content",
					alignSelf: isMobile ? "center" : "flex-start",
					borderRadius: 10,
				}}
			/>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
					alignItems: layoutHorizontal ? "flex-start" : "center",
				}}
				gap={2}
			>
				{(!!webContent?.titre || !!webContent?.description || !!webContent?.contenu) && (
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-start",
							flexDirection: "column",
							gap: 3,
							marginRight: layoutHorizontal ? 4 : 0,
							alignItems: layoutHorizontal ? "flex-start" : "center",
						}}
					>
						<Typography
							color={"#ffffff"}
							fontFamily={"Yeseva One"}
							fontSize={20}
							dangerouslySetInnerHTML={{ __html: webContent?.titre }}
						></Typography>
						<Typography
							color={"#ffffff"}
							fontFamily={"Branding SemiBold"}
							sx={{
								display: "-webkit-box",
								overflow: "hidden",
								WebkitBoxOrient: "vertical",
								WebkitLineClamp: 12,
							}}
							dangerouslySetInnerHTML={{ __html: webContent?.description || webContent?.contenu }}
						></Typography>
						<InexButton
							startIcon={<ShoppingBagOutlined sx={{ color: "#ffffff", fontSize: "22px !important" }} />}
							onClick={openShop}
							variant={"text"}
							textWithGradient={false}
							text={"Voir le produit"}
							textSx={{
								color: "white",
							}}
							sx={{
								backgroundColor: "#EF7D8F",
								width: 230,
								transition: "opacity 0.3s ease box-shadow 0.3s ease, transform 0.3s ease",
								"&:hover": {
									boxShadow: "0 0 20px 10px rgba(230, 138, 150, 0.5)",
									backgroundColor: "#EF7D8F",
									border: "none",
								},
							}}
						/>
					</Box>
				)}
			</Box>
		</Box>
	);
};

export default ProductPreview;
