import React from "react";
import { Swiper, SwiperSlide, useSwiper, useSwiperSlide } from "swiper/react";
import { ReactComponent as Clock } from "../../../resources/images/svg/odyssee/clock.svg";

import "swiper/css";
import { Box, Typography } from "@mui/material";
import InexButton from "../../InexButton";
import "swiper/css/effect-cards";

import "swiper/css/effect-coverflow";
import { EffectCoverflow, Navigation, Pagination } from "swiper/modules";
import useOdyssee from "../../../context/odyssee/useOdyssee";
import useOdysseeNavigation from "../../../context/odysseeNavigation/useOdyssee";
import { useIsMobile } from "../../../hooks/responsive";

const SummaryCard = ({ item, index, activeIndex, currentModuleIndex }) => {
	const { navigateToSummary } = useOdysseeNavigation();
	const { isActive } = useSwiperSlide();
	const swiper = useSwiper();
	const isMobile = useIsMobile();

	const isDisabled = React.useMemo(() => {
		return index > currentModuleIndex;
	}, [index, currentModuleIndex]);

	const { timeRecommended, timeOptional } = React.useMemo(() => {
		return item?.lecon?.reduce(
			(acc, value) => {
				if (!value.id) return acc;
				if (value.type === "Recommandé") {
					acc.timeRecommended += value?.minutes || 0;
				} else {
					acc.timeOptional += value?.minutes || 0;
				}
				return acc;
			},
			{ timeRecommended: 0, timeOptional: 0 }
		);
	}, [item]);

	const onClick = () => {
		if (index === activeIndex) return;
		if (index < activeIndex) {
			swiper.slidePrev();
		} else swiper.slideNext();
	};

	const devUrl = React.useMemo(() => {
		if (window.location.hostname === "localhost") {
			// return "dev/";
			return "";
		}
		return "";
	}, []);

	return (
		<Box
			onClick={onClick}
			sx={{
				backgroundColor: "#18122D",
				borderRadius: "20px",
				position: "relative",
			}}
		>
			{!!isDisabled && (
				<Box
					sx={{
						position: "absolute",
						top: 0,
						bottom: 0,
						right: 0,
						left: 0,
						backgroundColor: "#18122D",
						borderRadius: "20px",
						zIndex: 3,
						opacity: 0.5,
					}}
				/>
			)}
			<Box
				sx={{
					background: "rgba(255,255,255,0.20)",
					borderRadius: "20px",
					padding: 4,
					opacity: isActive ? 1 : 0.5,
					transition: "opacity 0.3s ease",
				}}
			>
				<Box
					sx={{
						transition: "all 0.3s",
						cursor: isActive ? "pointer" : "grab",
						position: "relative",
						height: isMobile ? 150 : 350,
						display: "flex",
						alignItems: "center",
					}}
					onClick={() => navigateToSummary(item.id)}
				>
					<img
						src={`https://medias.inrees.com/img/odyssees/${devUrl}modules/1_${item.id}.jpg`}
						style={{
							width: "100%",
							height: isMobile ? 150 : 350,
							position: "absolute",
							top: 0,
							left: 0,
							right: 0,
							bottom: 0,
							zIndex: 2,
							filter: "blur(10px)",
							transform: "scaleX(1.05)",
							opacity: 0.6,
						}}
					/>
					<img
						src={`https://medias.inrees.com/img/odyssees/${devUrl}modules/1_${item.id}.jpg`}
						style={{
							width: "100%",
							height: "auto",
							position: "relative",
							zIndex: 3,
							// border: "15px solid #27213B",
							backgroundColor: "rgba(39,33,59,0.62)",
							padding: 16,
							borderRadius: 10,
						}}
					/>
				</Box>
				<Typography
					textAlign={isMobile ? "center" : "left"}
					color={"#fff"}
					fontFamily={"Branding Bold"}
					fontSize={isMobile ? 20 : 26}
					lineHeight={1.3}
					mt={isMobile ? 2 : 0}
				>
					{item.titre}
				</Typography>
				<Box
					mt={2}
					mb={2}
					sx={{
						backgroundColor: "#FFFFFF1A",
						borderRadius: "50px",
						display: "flex",
						padding: "10px 20px",
						width: "fit-content",
						flexWrap: "wrap",
					}}
				>
					<Typography color={"#FFFFFF"} fontFamily={"Branding Medium"}>
						Module {String.fromCharCode(65 + index)}
					</Typography>
					<Clock stroke={"white"} style={{ marginLeft: 10, marginRight: 7 }} />
					<Typography color={"#FFFFFF"} fontFamily={"Branding Medium"}>
						entre {timeRecommended} min et {timeOptional}
					</Typography>
				</Box>
				{!isDisabled && (
					<InexButton
						disabled={isDisabled}
						onClick={() => navigateToSummary(item.id)}
						variant={"text"}
						textWithGradient={false}
						text={"Explorer ce module"}
						textSx={{
							color: "white",
							background: "transparent",
						}}
						sx={{
							backgroundColor: !isDisabled ? "#EF7D8F" : "#18122D",
							width: "100%",
						}}
					/>
				)}
			</Box>
		</Box>
	);
};

const SummaryCarousel = () => {
	const ref = React.useRef(null);
	const { modules, databaseModuleIndex } = useOdyssee();
	const [activeIndex, setActiveIndex] = React.useState(0);

	React.useEffect(() => {
		if (!databaseModuleIndex) return;
		ref?.current?.slideTo?.(databaseModuleIndex);
	}, [databaseModuleIndex]);

	return (
		<Box sx={{ width: "99vw", position: "relative" }}>
			<Swiper
				style={{ width: "100%", paddingBottom: 50 }}
				effect={"coverflow"}
				grabCursor={true}
				centeredSlides={true}
				slidesPerView={"auto"}
				className={"summary-odyssee-swiper"}
				coverflowEffect={{
					rotate: 0,
					stretch: 100,
					depth: 100,
					modifier: 2.5,
				}}
				navigation={true}
				onSwiper={(swiper) => (ref.current = swiper)}
				pagination={{
					clickable: true,
				}}
				onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
				modules={[EffectCoverflow, Pagination, Navigation]}
			>
				{modules?.map((item, index) => (
					<SwiperSlide key={item.id}>
						<SummaryCard
							item={item}
							index={index}
							activeIndex={activeIndex}
							currentModuleIndex={databaseModuleIndex}
						/>
					</SwiperSlide>
				))}
			</Swiper>
		</Box>
	);
};

export default SummaryCarousel;
