import React from "react";
import { Box, styled } from "@mui/material";
import InexButton from "../../../InexButton";
import { ReactComponent as Book } from "../../../../resources/images/svg/odyssee/book.svg";
import { ReactComponent as Film } from "../../../../resources/images/svg/odyssee/film.svg";

const BackgroundImage = styled("img")(({ theme }) => ({
	height: "100%",
	width: "100%",
	zIndex: 1,
	borderRadius: "10px",
	maxHeight: 500,
	objectFit: "cover",
}));

const Collection = ({ webContent, category = "books", setModalOpen }) => {
	const openContent = () => {
		setModalOpen(true);
	};
	const Icon = category === "books" ? Book : Film;

	return (
		<Box sx={{ width: "75%" }}>
			<Box
				sx={{
					width: "100%",
					borderRadius: "10px",
					"&::after": {
						position: "absolute",
						content: "''",
						backgroundColor: "#00000069",
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						borderRadius: "10px",
						maxHeight: 500,
					},
				}}
			>
				<BackgroundImage src={webContent.image}></BackgroundImage>
			</Box>
			<Box
				sx={{
					position: "absolute",
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<InexButton
					startIcon={<Icon />}
					onClick={openContent}
					variant={"text"}
					textWithGradient={false}
					text={category === "books" ? "Voir la collection de livres" : "Voir la collection de films"}
					textSx={{
						color: "white",
					}}
					sx={{
						backgroundColor: "#EF7D8F",
						width: 280,
						transition: "opacity 0.3s ease box-shadow 0.3s ease, transform 0.3s ease",
						"&:hover": {
							boxShadow: "0 0 20px 10px rgba(230, 138, 150, 0.5)",
							backgroundColor: "#EF7D8F",
							border: "none",
						},
					}}
				/>
			</Box>
		</Box>
	);
};

export default Collection;
